body, html, .App {
  margin: 0;
  padding: 0;
  height: 100vh;
  /*overflow: hidden;*/
  background-color: #000;
  color: #d2dae2;
  user-select: none;
}

.App {
  text-align: center;
}

.frames-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.viewer-container {
  flex-grow: 1;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  aspect-ratio: 720/1280;
}

.frames-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.frames-container._loading {

}

.frames-container > * {
  /*position: absolute;*/
  opacity: 0;
  max-width: 100%;
  max-height: 100%;
  display: none;
}

.frames-container > *._active {
  opacity: 1;
  display: block;
}


.seek-overlay {
  background: rgba(255, 0, 0, 0.0);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  opacity: 0;
}

.icons-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2px;
  opacity: 1;
}

.icons-container > * {
  display: flex;
  justify-content: space-between;
  height: 7cqh;
}

.icons-container .top {
  height: 11cqh;
}

.icons-container .top > * {
  height: 100%;
  margin: 2%;
}

.icons-container .arrows .arrow {
  height: 100%;
  width: 7cqh;
  cursor: pointer;
  z-index: 1100;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/public/assets/img/arrow.svg");
  transition-duration: 0.4s;
  transition-delay: 0.5s;
}
.viewer-container:hover .icons-container .arrows .arrow {
  opacity: 1;
  transition-delay: 0s;
}

.icons-container .arrows .arrow.right {
  rotate: 180deg;
}

.progress-bar {
  height: 2px;
  flex-shrink: 0;
}

.progress-bar .bar {
  height: 100%;
  background-color: #ffd600;
}